














































































































































































import Component, { mixins } from 'vue-class-component';
import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { Getter, namespace } from 'vuex-class';
import { loadView, loadWidget } from '@/utils/helpers';
import { IAPIKeys } from '@/types/types';
const apiKeyModule = namespace('apiKeys');

@Component({
  name: 'ProductIndex',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
    CopyAPISecretDialog: loadView(
      'pages/developers/dialogs/CopyAPISecretDialog'
    ),
  },
  filters: {
    mask(value: string) {
      return `${value.slice(0, 5)}xxxxxxxxxxxx${value.slice(10, 15)}xxxx`;
    },
  },
})
export default class ProductIndex extends mixins(WidgetMixins, DialogMixins) {
  @apiKeyModule.Getter('getAPIKeys') accessKeys!: IAPIKeys;
  @Getter('getCreditBalance') creditBalance!: number;
  copyKey(key: string): void {
    this.$copyText(key).then(
      () => {
        this.$store.dispatch('snackBarMessage', `Copied`, { root: true });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      },
      () => {
        this.$store.dispatch('snackBarMessage', `Unable to copy key`, {
          root: true,
        });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      }
    );
  }

  copySecret(secret: string): void {
    this.$copyText(secret).then(
      () => {
        this.$store.dispatch('snackBarMessage', `Copied`, { root: true });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      },
      () => {
        this.$store.dispatch('snackBarMessage', `Unable to copy secret`, {
          root: true,
        });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      }
    );
  }

  generateAPIKeys(): void {
    this.$store.dispatch('apiKeys/generateKey', { name: 'Kairos Afrika' });
  }

  revokeKeys(): void {
    const keys = this.$store.getters['apiKeys/getAPIKeys'];
    this.$store.dispatch('apiKeys/revokeKeys', { id: keys.uuid });
  }
  created(): void {
    this.$store.dispatch('apiKeys/details');
  }
}
